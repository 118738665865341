import { IAuthParams } from "../auth/types";
import { HF_tenant_key } from "../dealerAccounts/types";

export enum IVehicleType {
  CAR = "car",
  BOAT = "boat",
}

export enum IVehicleValuationSourceType {
  INTERNAL_USER = "internal_user",
  EXTERNAL_SOURCE = "external_source",
  SYSTEM = "system",
}

export enum IVehicleOperatorType {
  LEGAL_ENTITY = "legal_entity",
  INLINE = "inline",
}

export enum IVehicleOwnerType {
  HANDLARFINANS = "handlarfinans",
  LEGAL_ENTITY = "legal_entity",
  INLINE = "inline",
}

export enum IVehicleActorRelationType {
  LEGAL_ENTITY = "legal_entity",
  INLINE = "inline",
}

export enum IFuelType {
  PETROL = "petrol",
  DIESEL = "diesel",
  ELECTRIC = "electric",
  GAS = "gas",
  HYBRID = "hybrid",
  E85 = "e85",
  OTHER = "other",
}

export enum ITransmissionType {
  MANUAL = "manual",
  AUTOMATIC = "automatic",
  OTHER = "other",
}

export enum IDriveType {
  RWD = "RWD",
  FWD = "FWD",
  AWD = "AWD",
}

export enum IBoatType {
  MOTOR_SHIP = "motor_ship",
  SAIL_SHIP = "sail_ship",
  OTHER = "other",
}

interface ICurrentOperator {
  operator_type: IVehicleOperatorType;
  name?: string;
  org_no?: string;
  ssn?: string;
  address?: string | null;
  country_id?: string;
  entity_id?: string;
}

type IOwner = {
  owner_type: IVehicleOwnerType;
  name?: string;
  address?: string | null;
  hf_tenant_key?: HF_tenant_key;
  entity_id?: string;
  org_no?: string | null;
  ssn?: string | null;
  country_id?: string | null;
};

type IFinancier = {
  financier_type: IVehicleActorRelationType;
  name?: string;
  org_no?: string;
  country_id?: string;
  entity_id?: string;
};

type ILeasingProvider = {
  provider_type: IVehicleActorRelationType;
  name?: string;
  org_no?: string;
  country_id?: string;
  entity_id?: string;
};

type IRegistration = {
  market_id: string;
  reg_no: string;
  registered_at?: string;
};

interface ILocation {
  location_id: string;
}

interface IVehicleNotification {
  raw: string; //"yellow",
  type: string; //"import",
  message: string; //"The vehicle is imported.",
  provider_name: string; //"carinfo"
}
interface IVehicleWarning {
  raw: string; //"yellow",
  type: string; //"import",
  message: string; //"The vehicle is imported.",
  provider_name: string; //"carinfo"
}

interface IBoatEngine {
  engine_serial_number: string | null;
  engine_model: string | null;
  engine_manufacturing_year: string | null;
}

interface ICar {
  registration: IRegistration | null;
  first_registration_date: string | null;
  vin: string | null;
  make: string | null;
  make_id?: string;
  model_full: string | null;
  model_short?: string | null;
  model_id?: string;
  generation?: string | null;
  model_year: number | null;
  fuel_type: IFuelType | null;
  transmission: ITransmissionType | null;
  drive_type: IDriveType | null;
  power_kw: number | null;
  torque_nm: number | null;
  mileage_km: number | null;
}

interface IBoat {
  win: string | null;
  type_of_boat: IBoatType | null;
  engine: IBoatEngine | null;
}

interface IVehicle {
  vehicle_id: string;
  vehicle_type: IVehicleType | null;
  car?: ICar;
  boat?: IBoat;
  current_operator?: ICurrentOperator | null;
  current_owner: IOwner | null;
  financier?: IFinancier | null;
  leasing_provider?: ILeasingProvider | null;
  vat_deductable: boolean | null;
  warnings?: IVehicleWarning[];
  notifications?: IVehicleNotification[];
  location?: ILocation;
}

interface ICarForm {
  car: ICar;
  current_owner: IOwner | null;
  vat_deductable: boolean | null;
  vehicle_type: IVehicleType.CAR;
}

interface IVehicleFetchPayload extends IAuthParams {
  vehicle_id: string;
}

interface IVehicleResponse {
  vehicle_id: string;
  revision_id: string;
  vehicle_title: string;
  vehicle: IVehicle;
  created_at: string;
  deleted_at?: string;
  updated_at?: string;
}

interface IImportVehiclePayload extends IAuthParams {
  reg_or_vin: string;
  country_id?: string;
}

interface ICreateVehiclePayload extends IAuthParams {
  payload: Omit<IVehicle, "vehicle_id">;
}

interface ICreateVehicleResponse {
  vehicle_id: string;
  revision_id: string;
}

interface IEditVehiclePayload extends IAuthParams {
  vehicle_id: string;
  vehicle: IVehicle;
  revision_id: string;
}

interface IEditVehicleResponse {
  vehicle: IVehicle;
  revision_id: string;
}

export type {
  ICar,
  IBoat,
  IVehicle,
  ICarForm,
  IRegistration,
  ILocation,
  IOwner,
  IVehicleFetchPayload,
  IVehicleResponse,
  IImportVehiclePayload,
  ICreateVehiclePayload,
  ICreateVehicleResponse,
  IEditVehiclePayload,
  IEditVehicleResponse,
};
