import React from "react";
import { IVehicle, IVehicleType } from "../types";

export default function useGetVehicleType(vehicle: IVehicle | null | undefined): IVehicleType | null {
  const vehicleType = React.useMemo(() => {
    if (!vehicle) return null;
    if (vehicle.vehicle_type) {
      return vehicle.vehicle_type;
    } else if (vehicle.car) {
      return IVehicleType.CAR;
    } else if (vehicle.boat) {
      return IVehicleType.BOAT;
    }
    return null;
  }, [vehicle]);

  return vehicleType;
}
