import Authorize from "./Authorize";

enum Permissions {
  READ_OWN_USER = "read:own_user",
  UPDATE_OWN_USER = "update:own_user",
  READ_EXTERNAL_USER = "read:external_user",
  CREATE_EXTERNAL_USER = "create:external_user",
  UPDATE_EXTERNAL_USER = "update:external_user",
  DELETE_EXTERNAL_USER = "delete:external_user",
  READ_EXTERNAL_USER_ROLE = "read:external_user_role",
  GRANT_EXTERNAL_USER_ROLE = "grant:external_user_role",
  REVOKE_EXTERNAL_USER_ROLE = "revoke:external_user_role",
  READ_DEAL = "read:deal",
  CREATE_DEAL = "create:deal",
  UPDATE_DEAL = "update:deal",
  READ_VEHICLE = "read:vehicle",
  CREATE_VEHICLE = "create:vehicle",
  UPDATE_VEHICLE = "update:vehicle",
  READ_LEGAL_ENTITY = "read:legal_entity",
  CONNECT_CHAT = "connect:chat",
  // UPDATE_KYC_RECORD = "write:kyc_record",
  // WRITE_KYC_RECORD = "write:kyc_record",
  CREATE_KYC_APPLICATION = "create:kyc_application",
  READ_KYC_APPLICATION = "read:kyc_application",
  UPDATE_KYC_APPLICATION = "update:kyc_application",
  DELETE_KYC_APPLICATION = "delete:kyc_application",
  CREATE_FILE = "create:file",
  READ_FILE = "read:file",
  DELETE_FILE = "delete:file",
  UPDATE_FILE = "update:file",
  UPDATE_OWN_FILE = "update:own_file",
  DELETE_OWN_FILE = "delete:own_file",
  DOMAIN_SEARCH = "create:domain_search",
  CREATE_INVENTORY_VEHICLE = "create:inventory_vehicle",
  READ_INVENTORY_VEHICLE = "read:inventory_vehicle",
  UPDATE_INVENTORY_VEHICLE = "update:inventory_vehicle",
  DELETE_INVENTORY_VEHICLE = "delete:inventory_vehicle",
  CREATE_DEALER_FINANCE_REPORT = "create:dealer_finance_report",
  READ_INVOICE = "read:invoice",
  READ_VAT_TABLE = "read:vat_table",
  CREATE_CONSUMER_CREDIT_APPLICATION = "create:consumer_credit_application",
  READ_CONSUMER_CREDIT_APPLICATION = "read:consumer_credit_application",
  UPDATE_CONSUMER_CREDIT_APPLICATION = "update:consumer_credit_application",
  DELETE_CONSUMER_CREDIT_APPLICATION = "delete:consumer_credit_application",
  READ_DEALER_ACCOUNT = "read:dealer_account",
  UPDATE_DEALER_ACCOUNT = "update:dealer_account",
}

export { Permissions, Authorize };
