import { Box, Center, Flex } from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router";
import { AccessTokenContext } from "../../../auth/ProtectedComponent";
import { useGetDealQuery } from "../../../deals/service";
import useTranslate from "../../../locale/hooks/useTranslate";
import Icon from "../../../shared/Icon";
import Loader from "../../../shared/Loader";
import useGetVehicleType from "../../../vehicle/hooks/useGetVehicleType";
import { useGetVehicleQuery } from "../../../vehicle/service";
import { IVehicleType } from "../../../vehicle/types";
import { DealerRequiredAction } from "../../types";

type Props = {
  action: DealerRequiredAction;
};
export default function OwnershipTransferOutstanding({ action }: Props) {
  const translate = useTranslate();
  const navigate = useNavigate();
  const access_token = useContext(AccessTokenContext);

  const { data: deal, isLoading: fetchingDeal } = useGetDealQuery(
    { access_token, deal_id: action.parameters.deal_id || "" },
    { skip: !action.parameters.deal_id }
  );
  const { data: vehicle, isLoading: fetchingVehicle } = useGetVehicleQuery(
    {
      access_token,
      vehicle_id: deal?.deal?.vehicle.vehicle_id || "",
    },
    { skip: !deal?.deal?.vehicle.vehicle_id }
  );

  const goTo = () => {
    navigate(`/deal/${action.parameters.deal_id}/ownership`);
  };

  const vehicleType = useGetVehicleType(vehicle?.vehicle);

  const vehicleIdentifier = useMemo(() => {
    if (vehicleType === IVehicleType.BOAT) {
      return vehicle?.vehicle?.boat?.win || "";
    } else if (vehicleType === IVehicleType.CAR) {
      return `${vehicle?.vehicle?.car?.registration?.reg_no}, ${vehicle?.vehicle?.car?.make} ${vehicle?.vehicle?.car?.model_full}`;
    }
    return "";
  }, [vehicle?.vehicle, vehicleType]);

  if (fetchingDeal || fetchingVehicle)
    return (
      <Center py="4">
        <Loader />
      </Center>
    );

  return (
    <Flex onClick={goTo} cursor="pointer" gap="2" align="center">
      <Center borderRadius="full" bgColor="reds.100" p="2" flex="0 0 auto" minW="0">
        <Icon name="key" color="reds.700" size="32px" />
      </Center>

      <Box fontWeight="medium">
        {translate(`required_actions.action_types.${action.action_type}`)}

        <Box as="span" color="orange.700">
          {vehicleIdentifier}
        </Box>
      </Box>
    </Flex>
  );
}
